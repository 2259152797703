<adapt-dialog [maxWidth]="640"
              [disallowCancel]="!!dialogData.disallowCancel">
    <h3 adapt-dialog-title>Start adapt HQ subscription</h3>
    <ng-container adapt-dialog-content>
        <ng-container *ngIf="isIntroPage">
            <adapt-styled-implementation-kit [articleId]="StartSubscriptionArticle"
                                             contentStyle="embedded"></adapt-styled-implementation-kit>
        </ng-container>

        <ng-container *ngIf="!isIntroPage">
            <adapt-select-payment-frequency *ngIf="account"
                                            title="Payment Frequency"
                                            [account]="account"
                                            (selectionChanged)="onSelect($event)"></adapt-select-payment-frequency>
            <h4>Credit card details</h4>
            <ng-container *adaptLoading="cardDetailsLoading">
                <adapt-update-credit-card-details *ngIf="!cardDetails"
                                                  [updateCreditCardInput]="creditCardInput"
                                                  (cardSet)="cardSetCorrectly = $event"></adapt-update-credit-card-details>

                <adapt-configure-credit-card *ngIf="cardDetails"
                                             [(cardDetails)]="cardDetails"
                                             [organisationId]="creditCardInput.organisationIdentifier.organisationId"
                                             [eulaToken]="creditCardInput.organisationIdentifier.eulaToken"
                                             [allowEditing]="true"
                                             (cardIsSet)="cardSetCorrectly = $event"></adapt-configure-credit-card>
            </ng-container>
            <div *ngIf="account.extensions.canResumeSubscriptionWithoutCharge()"
                 class="alert alert-info mt-3 mb-0">
                Your subscription has already been paid for the period ending <strong>{{account.nextSubscriptionInvoiceDate |
                    adaptDate}}</strong>.
                You will not be charged again until after this date.
            </div>
        </ng-container>
    </ng-container>

    <div adapt-dialog-footer
         class="w-100 d-flex gap-1"
         *ngIf="isIntroPage; else showSaveButton">
        <button adaptButton="workflowNext"
                class="ms-auto"
                (click)="isIntroPage = false"
                data-test="next-page-button">Next</button>
        <button *ngIf="!dialogData.disallowCancel"
                adaptButton="iconlessCancel"
                (click)="cancel()"></button>
    </div>
    <ng-template #showSaveButton>
        <div adapt-dialog-footer
             class="w-100 d-flex">
            <button adaptButton="workflowPrevious"
                    (click)="isIntroPage = true">Back</button>
            <adapt-standard-dialog-buttons adapt-dialog-footer
                                           (cancelClick)="cancel()"
                                           saveButtonPreset="primary"
                                           [saveBlockingClick]="saveAndClose"
                                           [saveIsDisabled]="!cardSetCorrectly || !account.contactName || !account.contactEmail"
                                           [hideCancelButton]="!!dialogData.disallowCancel"
                                           [saveText]="account.extensions.isPendingCancellation ? 'Resume subscription!' : 'Start subscription!'"></adapt-standard-dialog-buttons>
        </div>
    </ng-template>
</adapt-dialog>
